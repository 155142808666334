import { useState } from 'react';
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { Carousel } from 'react-responsive-carousel';

import { Tour as BaseTour } from 'components/shared/Tour';
import { useVendor, useWindowSize } from 'hooks';

import {
  images as invoiceImages,
  steps as invoiceSteps,
  name as invoicingTourName,
} from './invoicingTour';

const dealerTaskStep = {
  content: "Navigates to the vehicle's tasks",
  spotlightPadding: 0,
  target: '.tour-carousel',
  locale: {
    last: 'Close ',
  },
};

const vendorTaskStep = {
  content:
    "Navigates to the vehicle's vendor tasks where invoices can be created",
  spotlightPadding: 0,
  target: '.tour-carousel',
  locale: {
    last: 'Close ',
  },
};

type Props = {
  name: string;
  showTour: boolean;
};
const Tour = ({ name, showTour }: Props) => {
  const { isVendor, isLoading } = useVendor();
  const { isMobileViewport } = useWindowSize();
  const isMobile = isMobileViewport();
  const images = invoiceImages;
  const steps = invoiceSteps;
  const tourSteps = isVendor
    ? [...steps, vendorTaskStep]
    : [...steps, dealerTaskStep];
  const [stepIndex, setStepIndex] = useState(0);

  if (isLoading) {
    return null;
  }

  const imageHeight = isMobile ? 335 : 740;
  const imageWidth = isMobile ? 300 : 1030;
  const ratio = imageHeight / imageWidth;

  const handleCallback = ({ action, index, status, type }: CallBackProps) => {
    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }
    if (
      ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED] as string[]).includes(
        status
      )
    ) {
      // Closes Carousel
      setStepIndex(0);
    }
  };

  return (
    <div>
      <BaseTour
        name={invoicingTourName}
        callback={handleCallback}
        menuTriggerType={isMobile ? 'hamburgerMenu' : 'profileMenu'}
        showTour={showTour}
        localStorageKey={'tour_hasShownInvoicingTour'}
        steps={tourSteps}
        continuous
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 9999,
            backgroundColor: '#000000a6',
          },
        }}
      />
      {stepIndex > 0 && (
        <div
          className="tour-carousel"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: `translate(-50%, ${isMobile ? '-40' : '-50'}%)`,
            height: Math.floor(imageHeight * ratio),
            width: imageWidth,
            border: '1px solid #000',
            zIndex: 1,
          }}
        >
          <Carousel
            selectedItem={stepIndex - 1}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
          >
            {images.map((image) => {
              return (
                <img
                  key={image.alt} // Add a unique key prop for each image
                  alt={image.alt}
                  src={isMobile ? image.mobileSrc : image.desktopSrc}
                />
              );
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default Tour;
