import { Button as MUIButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { navigate, RouteComponentProps } from '@reach/router';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import useGetInvoiceQuery from 'api/invoicing/useGetInvoiceQuery';
import useSendInvoiceMutation from 'api/invoicing/useSendInvoiceMutation';
import { strings } from 'common';
import ApiError from 'components/shared/ApiError';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useVendor } from 'hooks';

import { Invoice } from '../Invoice';
import Footer from './Footer';

import './ViewInvoice.scss';

type Props = RouteComponentProps & { invoiceId?: string };

const ViewInvoice = ({ invoiceId }: Props) => {
  const { isVendor } = useVendor();
  const getInvoiceQuery = useGetInvoiceQuery(invoiceId);
  const sendInvoiceMutation = useSendInvoiceMutation();
  const invoice = getInvoiceQuery?.data?.data;
  const apiError = getInvoiceQuery.error ?? sendInvoiceMutation.error;

  const [invoiceNumber, setInvoiceNumber] = useState(
    invoice?.invoiceNumber?.toString() ?? ''
  );
  useEffect(() => {
    if (getInvoiceQuery.isSuccess && invoice?.invoiceNumber) {
      setInvoiceNumber(invoice?.invoiceNumber?.toString() ?? '');
    }
  }, [getInvoiceQuery.isSuccess, invoice?.invoiceNumber]);

  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(
    invoice?.invoiceNumber?.toString() ?? ''
  );
  useEffect(() => {
    if (getInvoiceQuery.isSuccess && invoice?.purchaseOrderNumber) {
      setPurchaseOrderNumber(invoice?.purchaseOrderNumber?.toString() ?? '');
    }
  }, [getInvoiceQuery.isSuccess, invoice?.purchaseOrderNumber]);

  const handleBack = () => {
    // Multiple vehicles can be part of invoice. Going back where you came from
    // using the browser history is the best solution for now
    navigate?.(-1);
  };

  const handleInvoiceNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInvoiceNumber(event.target.value);
    },
    []
  );

  const handlePurchaseOrderNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPurchaseOrderNumber(event.target.value);
    },
    []
  );

  let content = null;
  if (getInvoiceQuery.isLoading) {
    content = <LoadingIndicator />;
  } else if (invoice && getInvoiceQuery.isSuccess) {
    content = (
      <Invoice
        invoice={invoice}
        onInvoiceNumberChange={handleInvoiceNumberChange}
        onPurchaseOrderNumberChange={handlePurchaseOrderNumberChange}
      />
    );
  }

  const canPayInvoice = invoice?.invoiceStatus !== 'PAID' && !isVendor;
  const canSendInvoice = !invoice?.invoiceNumber && isVendor;
  const canUpdateInvoice =
    invoice?.invoiceStatus === 'PAID' &&
    !invoice.purchaseOrderNumber &&
    !isVendor;

  return (
    <div className="vendor-view-invoice">
      <header className="vendor-view-invoice-header">
        <MUIButton
          variant="text"
          color="secondary"
          style={{ fontWeight: 'bold' }}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
        >
          {strings.BACK}
        </MUIButton>
      </header>
      <main className="vendor-view-invoice-content">{content}</main>
      <Footer
        invoice={invoice}
        invoiceNumber={invoiceNumber}
        purchaseOrderNumber={purchaseOrderNumber}
        showPayButton={canPayInvoice}
        showSendButton={canSendInvoice}
        showUpdateButton={canUpdateInvoice}
      />
      {apiError && <ApiError error={apiError} />}
    </div>
  );
};

export default ViewInvoice;
