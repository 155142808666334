import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

import 'typeface-open-sans';
import 'typeface-source-sans-pro';

import App from 'components/App';

import { initializeFirebase } from './firebase';
import * as Telemetry from './utils/telemetry';

import './scss/App.scss';
import './index.scss';

declare global {
  interface Window {
    recon: any;
    service: any;
  }
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_CONTAINER_ID ?? '',
};

if (tagManagerArgs.gtmId) {
  TagManager.initialize(tagManagerArgs);
}

// should be called as early as possible, at least before any XHRs
Telemetry.init();

const version = `${process.env.REACT_APP_VERSION}`;
const versionSummary = `Velocity Automotive Web-${version}`;
// eslint-disable-next-line no-console
console.info(versionSummary);
if (window) {
  window.recon = { version, versionSummary, gtmInit: false };
}

// Google Analytics
ReactGA.initialize(
  [
    { trackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID ?? '' }, // Depreciated Universal Analytics
    { trackingId: process.env.REACT_APP_GOOGLE_FOUR_TRACKING_ID ?? '' }, // New Google Analytics 4
  ],
  {
    gtagOptions: {
      send_page_view: false, // Turn off default page view
    },
  }
);
if (window.recon) {
  window.recon.gtmInit = true;
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

initializeFirebase();
