import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { useState } from 'react';

import { VehicleTasksApiResponse } from 'api';
import { permissions, strings } from 'common';
import theme from 'common/theme';
import { usePermissions, useTaskPermissions } from 'hooks';
import { VehicleSummary } from 'models';
import { currencyFormatter } from 'utils/formatter';

import MobileAddFlatTaskDialog from '../MobileAddFlatTaskDialog/MobileAddFlatTaskDialog';
import MobileFlatTaskAdd from '../MobileFlatTasksBody/MobileFlatTaskAdd';

type Props = {
  totals: VehicleTasksApiResponse['meta'];
  vehicle: VehicleSummary;
};

const MobileFlatTasksFooter = ({ totals, vehicle }: Props) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { hasPermissions } = usePermissions();
  const hasAddTaskPermission = hasPermissions([
    permissions.INVENTORY_VDP_TASKS_CREATE,
  ]);
  // Permissions
  const { hasTaskLaborCostViewPermission, hasTaskPartsCostViewPermission } =
    useTaskPermissions();

  let hours = (totals?.overallTaskTotal?.hoursTotal ?? 0).toString();
  let labor = currencyFormatter(totals?.overallTaskTotal?.laborTotal ?? 0);
  let parts = currencyFormatter(totals?.overallTaskTotal?.partsTotal ?? 0);
  let total = currencyFormatter(totals?.overallTaskTotal?.total ?? 0);
  if (!hasTaskLaborCostViewPermission) {
    hours = '--';
    labor = '--';
  }

  if (!hasTaskPartsCostViewPermission) {
    parts = '--';
  }

  if (!hasTaskLaborCostViewPermission || !hasTaskPartsCostViewPermission) {
    total = '--';
  }

  return (
    <div className={classes.container} id={'mobile-task-footer-container'}>
      <Box
        className={classes.columnCellContainer}
        style={{ paddingTop: theme.spacing(1) }}
      >
        <Typography variant="body2" className={classes.columnCellLabel}>
          {strings.TASKS}
        </Typography>
        <Typography variant="body2" className={classes.columnCellLabel}>
          {strings.HOURS}
        </Typography>
        <Typography variant="body2" className={classes.columnCellLabel}>
          {strings.LABOR}
        </Typography>
        <Typography variant="body2" className={classes.columnCellLabel}>
          {strings.PARTS}
        </Typography>
        <Typography variant="body2" className={classes.columnCellLabel}>
          {strings.TOTAL}
        </Typography>
      </Box>
      <Box className={classes.columnCellContainer}>
        <Typography
          variant="body2"
          className={classes.columnCell}
          id={'mobile-task-footer-task-count'}
        >
          {totals?.returnedRecords ?? 0}
        </Typography>
        <Typography
          variant="body2"
          className={classes.columnCell}
          id={'mobile-task-footer-hours'}
        >
          {hours}
        </Typography>
        <Typography
          variant="body2"
          className={classes.columnCell}
          id={'mobile-task-footer-labor'}
        >
          {labor}
        </Typography>
        <Typography
          variant="body2"
          className={classes.columnCell}
          id={'mobile-task-footer-parts'}
        >
          {parts}
        </Typography>
        <Typography
          variant="body2"
          className={`${classes.columnCell} ${classes.bold}`}
          id={'mobile-task-footer-total'}
        >
          {total}
        </Typography>
      </Box>
      <Button
        variant="contained"
        id={'mobile-task-add-button'}
        color={'secondary'}
        className={classes.addTask}
        disabled={!hasAddTaskPermission}
        onClick={() => setIsModalOpen(true)}
      >
        {strings.ADD_TASK}
      </Button>
      {vehicle?.vehicleCard?.id && (
        <MobileAddFlatTaskDialog
          title={strings.ADD_TASK}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <MobileFlatTaskAdd
            vehicleId={vehicle?.vehicleCard?.id}
            onSaveComplete={() => setIsModalOpen(false)}
          />
        </MobileAddFlatTaskDialog>
      )}
    </div>
  );
};

const fontStyle: CreateCSSProperties<{}> = {
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '12px',
};

const cellContainerStyle: CreateCSSProperties<{}> = {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  columnGap: '10px',
  borderRadius: 3,
  justifyContent: 'space-between',
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'stretch',
      flexDirection: 'column',
      padding: `0 ${theme.spacing(2)}px`,
      backgroundColor: '#e2e2e2',
      position: 'fixed',
      zIndex: 9999,
      bottom: 0,
      left: 0,
      right: 0,
    },
    columnFooterContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      columnGap: '10px',
      //padding: theme.spacing(0.8),
      borderRadius: 3,
      justifyContent: 'space-between',
    },
    columnCellContainer: {
      ...cellContainerStyle,
    },
    columnCell: {
      ...fontStyle,
      textAlign: 'center',
      flex: 1,
    },
    columnCellLabel: {
      ...fontStyle,
      textAlign: 'center',
      flex: 1,
      color: '#7e8487',
    },
    link: {
      ...fontStyle,
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
    },
    addTask: {
      fontWeight: 700,
      color: theme.palette.primary.light,
      margin: `${theme.spacing(2)}px 0`,
    },
    bold: {
      fontWeight: 700,
    },
  })
);

export default MobileFlatTasksFooter;
