import { Step } from 'react-joyride';

import { styles } from 'components/shared/Tour';

import imageDesktopStep1 from './images/desktop/invoicingTourDesktop1.png';
import imageDesktopStep2 from './images/desktop/invoicingTourDesktop2.png';
import imageDesktopStep3 from './images/desktop/invoicingTourDesktop3.png';
import imageDesktopStep4 from './images/desktop/invoicingTourDesktop4.png';
import imageDesktopStep5 from './images/desktop/invoicingTourDesktop5.png';
import imageMobileStep1 from './images/mobile/invoicingTourMobile1.png';
import imageMobileStep2 from './images/mobile/invoicingTourMobile2.png';
import imageMobileStep3 from './images/mobile/invoicingTourMobile3.png';
import imageMobileStep4 from './images/mobile/invoicingTourMobile4.png';
import imageMobileStep5 from './images/mobile/invoicingTourMobile5.png';

export const name: string = 'Invoicing Tour';

export const steps: Step[] = [
  {
    target: '.vendor-inventory-list-content',
    title: 'Invoicing Tour',
    content:
      "Welcome to the vehicle tasks page.  Let's take a quick tour to familiarize you with tasks and creating invoices.",
    disableBeacon: true,
    placement: 'center',
    styles: {
      ...styles,
      tooltipContainer: {
        textAlign: 'left',
      },
    },
    locale: {
      next: 'Start',
    },
  },
  {
    content: 'Task that is part of an invoice for the vehicle',
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
  {
    content: 'Payment status of the invoiced task',
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
  {
    content:
      'Navigates to the vehicle"s invoices list and displays the invoice payment status',
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
  {
    content: 'Navigates to the vehicle"s notes',
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
];

export const images = [
  {
    alt: 'step 1',
    mobileSrc: imageMobileStep1,
    desktopSrc: imageDesktopStep1,
  },
  {
    alt: 'step 2',
    mobileSrc: imageMobileStep2,
    desktopSrc: imageDesktopStep2,
  },
  {
    alt: 'step 3',
    mobileSrc: imageMobileStep3,
    desktopSrc: imageDesktopStep3,
  },
  {
    alt: 'step 4',
    mobileSrc: imageMobileStep4,
    desktopSrc: imageDesktopStep4,
  },
  {
    alt: 'step 5',
    mobileSrc: imageMobileStep5,
    desktopSrc: imageDesktopStep5,
  },
];
