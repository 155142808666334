import { useQuery } from '@tanstack/react-query';

import { APIResponse, defaultMetaQueryFn } from 'api/useAPI';
import { VehicleSummary } from 'models';

import { getInventoryQueryString } from '.';

/**
 * @package api/inventory/invoice-summary
 * @description - Get summaries for vehicles with invoices
 */
export function useGetDealerInventoryQuery(enabled = false) {
  const queryKey = getDealerInventoryQueryKey();
  return useQuery<APIResponse<VehicleSummary[]>>(
    queryKey,
    () => defaultMetaQueryFn(`${queryKey[0]}?${queryKey[1]}`),
    {
      enabled,
    }
  );
}

/**
 * @package api/inventory/invoice-summary by vin™
 * @description - Get summary for vehicle with vendor tasks
 */
export function useGetDealerInventoryByVinQuery(
  enabled: boolean,
  vin: string | null
) {
  const url = getDealerInventoryByVinQueryKey()?.[0];
  return useQuery<APIResponse<VehicleSummary[]>>(
    ['/inventory', vin],
    () => defaultMetaQueryFn(`${url}?s=${vin}`),
    {
      enabled,
    }
  );
}

export const getDealerInventoryQueryKey = () => {
  const path = '/inventory/invoicedInventories';
  const queryString = getInventoryQueryString();
  return [path, queryString];
};

export const getDealerInventoryByVinQueryKey = () => {
  const path = '/inventory';
  const queryString = getInventoryQueryString();
  return [path, queryString];
};

export default useGetDealerInventoryQuery;
