import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useMemo, useRef, useState } from 'react';

import { useCreateVehicleTasksBulk, useTaskTemplates } from 'api';
import { strings } from 'common';
import Alert from 'components/shared/Alert';
import { Task, TaskTemplate } from 'models';

import { newTask, taskFromTaskTemplate } from '../taskUtils';

type Props = {
  vehicleId: string;
  onSaveComplete: () => void;
};

const MobileFlatTaskAdd = ({ vehicleId, onSaveComplete }: Props) => {
  const classes = useStyles();
  const [apiError, setApiError] = useState('');
  const [showRequiredTaskMessage, setShowRequiredTaskMessage] = useState(false);
  const createOrUpdateVehicleTasksBulkQuery =
    useCreateVehicleTasksBulk(vehicleId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const selectedTemplateRef = useRef<TaskTemplate>();
  const [checked, setChecked] = useState<
    { id: string; taskTemplate: TaskTemplate }[]
  >([]);
  const [customTasks, setCustomTasks] = useState<Record<string, TaskTemplate>>(
    {}
  );
  const [filterText, setFilterText] = useState('');
  const { data: taskTemplateData } = useTaskTemplates();
  let { data: taskTemplates = [] } = taskTemplateData ?? {};

  if (taskTemplates[0].id === 'custom' && !customTasks['custom']) {
    setCustomTasks({
      [taskTemplates[0].id]: taskTemplates[0],
    });
  }

  const filteredTemplates = useMemo(() => {
    const templateData: Record<string, TaskTemplate[]> = {};
    const filterTextLowerCase = filterText.toLowerCase();
    const [, ...otherTemplates] = taskTemplates; // remove custom task template
    [...Object.values(customTasks), ...otherTemplates]
      .filter(
        (tt) =>
          tt?.label?.toLowerCase().match(filterTextLowerCase) ||
          tt?.description?.toLowerCase().match(filterTextLowerCase) ||
          tt?.tenantName?.toLowerCase().match(filterTextLowerCase)
      )
      .forEach((tt) => {
        if (templateData?.[tt.tenantName]?.length) {
          templateData[tt.tenantName] = [...templateData[tt.tenantName], tt];
        } else {
          templateData[tt.tenantName] = [tt];
        }
      });
    return templateData;
  }, [customTasks, filterText, taskTemplates]);

  const handleToggle = (taskTemplate: TaskTemplate) => {
    const currentIndex = checked.findIndex(
      (item) => item.id === taskTemplate.id
    );
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push({ id: taskTemplate.id, taskTemplate });
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCustomTextChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    taskTemplateId: string
  ) => {
    setCustomTasks({
      ...customTasks,
      [taskTemplateId]: {
        ...customTasks?.[taskTemplateId],
        label: e.target.value,
      },
    });
  };

  const handleOpenMenu = (
    e: React.MouseEvent<HTMLButtonElement>,
    taskTemplate: TaskTemplate
  ) => {
    setAnchorEl(e?.currentTarget);
    selectedTemplateRef.current = taskTemplate;
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAddCustomTask = () => {
    handleCloseMenu();

    const newCustomTask = {
      ...customTasks?.['custom'],
      id: `custom${Object.entries(customTasks)?.length + new Date().getTime()}`,
      label: '',
    };
    setCustomTasks({
      ...customTasks,
      [newCustomTask.id]: newCustomTask,
    });
  };
  const handleRemoveCustomTask = () => {
    handleCloseMenu();

    if (selectedTemplateRef?.current) {
      const tasks = { ...customTasks };
      delete tasks[selectedTemplateRef.current?.id];
      if (checked.find((item) => item.id === selectedTemplateRef.current?.id)) {
        handleToggle(selectedTemplateRef.current);
      }

      setCustomTasks(tasks);
    }
  };

  const handleAdd = async () => {
    const tasksToCreate: Task[] = [];
    checked.forEach(({ id, taskTemplate }) => {
      if (taskTemplate) {
        const task = taskFromTaskTemplate(newTask(), taskTemplate);
        if (id.startsWith('custom')) {
          task.label = customTasks[id]?.label;
        }
        tasksToCreate.push(task);
      }
    });

    if (tasksToCreate.length) {
      try {
        await createOrUpdateVehicleTasksBulkQuery.mutateAsync(tasksToCreate);
        onSaveComplete();
      } catch (e) {
        setApiError(strings.API_MESSAGE);
      }
    } else {
      setShowRequiredTaskMessage(true);
    }
  };

  return (
    <Box>
      <Box className={classes.header}>
        <TextField
          label="Filter Tasks"
          size="small"
          variant="outlined"
          className={classes.filter}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </Box>
      {Object.entries(filteredTemplates).length ? (
        <List className={classes.root} subheader={<li />}>
          {Object.entries(filteredTemplates).map(
            ([tenantName, taskTemplates]) => {
              return (
                <li
                  key={`section-${tenantName}`}
                  className={classes.listSection}
                >
                  <ul className={classes.ul}>
                    <ListSubheader>
                      {tenantName === 'null' ? '' : tenantName}
                    </ListSubheader>
                    {taskTemplates?.map((taskTemplate) => {
                      return (
                        <ListItem
                          key={taskTemplate.id}
                          dense
                          button
                          onClick={() => handleToggle(taskTemplate)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={
                                checked.findIndex(
                                  (item) => item.id === taskTemplate.id
                                ) !== -1
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                'aria-labelledby': taskTemplate.id,
                              }}
                            />
                          </ListItemIcon>
                          {taskTemplate.id.startsWith('custom') ? (
                            <ListItemSecondaryAction
                              style={{
                                left: '70px',
                              }}
                            >
                              <ListItem className={classes.customTaskItem}>
                                <TextField
                                  label="Custom Task"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    handleCustomTextChange(e, taskTemplate.id)
                                  }
                                  className={classes.textField}
                                />

                                <IconButton
                                  size="small"
                                  onClick={(e) =>
                                    handleOpenMenu(e, taskTemplate)
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </ListItem>
                            </ListItemSecondaryAction>
                          ) : (
                            <ListItemText
                              primary={`${taskTemplate.label}`}
                              secondary={taskTemplate.description}
                            />
                          )}
                        </ListItem>
                      );
                    })}
                  </ul>
                </li>
              );
            }
          )}
        </List>
      ) : (
        <Typography variant="body1" className={classes.message}>
          Task not found!
        </Typography>
      )}
      <Box className={classes.footer}>
        <Divider />
        <Box className={classes.actionContainer}>
          <Button
            variant="contained"
            className={classes.addButton}
            color={'secondary'}
            disabled={createOrUpdateVehicleTasksBulkQuery.isLoading}
            onClick={handleAdd}
          >
            {strings.ADD}
          </Button>
        </Box>
      </Box>
      <Menu
        style={{ zIndex: 9999 }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleAddCustomTask}>Add Task</MenuItem>
        <MenuItem
          disabled={selectedTemplateRef?.current?.id === 'custom'}
          onClick={handleRemoveCustomTask}
        >
          Remove Task
        </MenuItem>
      </Menu>
      <Alert
        open={!!apiError}
        duration={3000}
        handleClose={() => setApiError('')}
        contentProps={{
          variant: 'error',
          message: apiError,
          onClose: () => setApiError(''),
        }}
      />
      <Alert
        open={showRequiredTaskMessage}
        duration={3000}
        handleClose={() => setShowRequiredTaskMessage(false)}
        contentProps={{
          variant: 'warning',
          message: 'At least one task is required!',
          onClose: () => setShowRequiredTaskMessage(false),
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}px`,
  },
  filter: {
    width: '100%',
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    overflow: 'auto',
    top: '108px',
    left: 0,
    right: 0,
    bottom: 70,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  footer: {
    height: '70px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  actionContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
    paddingBottom: 0,
    display: 'flex',
    justifyContent: 'stretch',
    flex: 1,
  },
  addButton: {
    flex: 1,
    fontWeight: 700,
    color: theme.palette.primary.light,
    margin: `${theme.spacing(2)}px 0`,
  },
  message: {
    textAlign: 'center',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  customTaskItem: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'space-between',
  },
  textField: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
}));

export default MobileFlatTaskAdd;
