import {
  Box,
  Button,
  ButtonBase,
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMemo, useRef, useState } from 'react';

import {
  useCreateOrUpdateVehicleTask,
  useDeleteVehicleTask,
  useUpdateVehicleTasksStatus,
} from 'api';
import { strings } from 'common';
import theme from 'common/theme';
import Alert from 'components/shared/Alert';
import { useTaskPermissions } from 'hooks';
import { Task, TaskStatus, TaskTemplate, User, VehicleSummary } from 'models';
import { currencyFormatter } from 'utils/formatter';
import { STATUS } from 'utils/tasks';
import { getUsersFullName } from 'utils/user';

import TaskPhotos from '../TaskPhotos';
import MobileFlatTaskMenu from './MobileFlatTaskMenu';
import MobileMediaList from './MobileMediaList';

type Props = {
  task: Task | undefined;
  taskTemplates: TaskTemplate[];
  users: User[];
  vehicle: VehicleSummary;
  onSaveComplete: () => void;
};

const MobileFlatTaskEdit = ({
  task,
  taskTemplates,
  users,
  vehicle,
  onSaveComplete,
}: Props) => {
  const classes = useStyles();
  const hasSetInitialPrice = useRef(false);
  const [apiError, setApiError] = useState('');
  const [statusLabel, setStatusLabel] = useState(
    task ? STATUS[task.status].toUpperCase() : ''
  );
  const [statusValue, setStatusValue] = useState(task?.status ?? 'DRAFT');
  const [showDrawer, setShowDrawer] = useState(false);
  const createOrUpdateVehicleTaskQuery = useCreateOrUpdateVehicleTask(
    vehicle?.vehicleCard?.id
  );
  const updateVehicleTasksStatus = useUpdateVehicleTasksStatus(
    vehicle?.vehicleCard?.id
  );
  const deleteVehicleTaskQuery = useDeleteVehicleTask(
    vehicle?.vehicleCard?.id,
    task?.id
  );

  // template is not updated on server. This won't work until service is fixed
  const selectedTaskTemplate = useMemo(() => {
    return taskTemplates.find((item) => {
      return item.id === task?.taskTemplateId;
    });
  }, [taskTemplates, task?.taskTemplateId]);

  const [taskTemplate, setTaskTemplate] = useState(selectedTaskTemplate);
  const selectedAssignee = useMemo(() => {
    return users.find((user) => {
      return user.id === task?.assignedTo?.id;
    });
  }, [task, users]);

  const [assignee, setAssignee] = useState(selectedAssignee);
  const [description, setDescription] = useState(task?.notes ?? '');
  const [rate, setRate] = useState<string>(
    (task?.laborRate?.amount ?? 0).toString()
  );
  const [hours, setHours] = useState<string>(
    (task?.laborHours ?? 0).toString()
  );
  const [labor, setLabor] = useState<string>(
    (task?.laborPrice?.amount ?? 0).toString()
  );
  const [parts, setParts] = useState<string>(
    (task?.partsPrice?.amount ?? 0).toString()
  );

  const parseStringToNumber = (value: string) => {
    const number = parseFloat(value);
    return isNaN(number) ? 0 : number;
  };

  const total = useMemo(() => {
    const laborTotal =
      rate === '' || hours === ''
        ? parseStringToNumber(labor)
        : parseStringToNumber(rate) * parseStringToNumber(hours);

    const totalPrice =
      !hasSetInitialPrice.current && task?.price?.amount
        ? task.price.amount
        : laborTotal + parseStringToNumber(parts);

    hasSetInitialPrice.current = true;
    return totalPrice;
  }, [hours, labor, parts, rate, task?.price]);

  // Permissions
  const {
    hasTaskUpdatePermission,
    hasTaskAttachmentViewPermission,
    hasTaskLaborCostViewPermission,
    hasTaskLaborCostUpdatePermission,
    hasTaskPartsCostViewPermission,
    hasTaskPartsCostUpdatePermission,
  } = useTaskPermissions();

  const taskHasInvoice = !!task?.invoiceId;

  const disabled =
    task?.status === 'APPROVED_PENDING_COMPLETE' ||
    task?.status === 'COMPLETE' ||
    task?.status === 'DENIED' ||
    !hasTaskUpdatePermission ||
    taskHasInvoice;

  const photosDisabled =
    task?.status === 'COMPLETE' ||
    task?.status === 'DENIED' ||
    !hasTaskUpdatePermission ||
    taskHasInvoice;

  const assigneeDisabled =
    task?.status === 'COMPLETE' ||
    task?.status === 'DENIED' ||
    !hasTaskUpdatePermission ||
    taskHasInvoice;

  if (!task) {
    return <Typography>Task not found</Typography>;
  }

  const handleSave = async () => {
    const updateTaskStatus = async () => {
      if (task.id != null && task.status !== statusValue) {
        await updateVehicleTasksStatus.mutateAsync({
          status: statusValue,
          taskIds: [task.id],
        });
      }
    };

    const updateTask = async () => {
      await createOrUpdateVehicleTaskQuery.mutateAsync({
        ...task,
        label: taskTemplate?.label ?? '',
        taskTemplateId: !taskTemplate?.id.startsWith('custom')
          ? taskTemplate?.id
          : undefined,
        assignedTo: assignee,
        assignedToId: assignee?.id,
        notes: description,
        laborRate: {
          currency: task.laborRate?.currency,
          amount: rate === '' ? 0 : parseFloat(rate),
        },
        laborHours: hours === '' ? 0 : parseFloat(hours),
        laborPrice: {
          currency: task.laborPrice?.currency,
          amount: labor === '' ? 0 : parseFloat(labor),
        },
        partsPrice: {
          currency: task.partsPrice?.currency,
          amount: parts === '' ? 0 : parseFloat(parts),
        },
      });
    };
    try {
      await Promise.all([updateTaskStatus(), updateTask()]);
      onSaveComplete();
    } catch (error) {
      console.log('Error updating task', error);
      setApiError(strings.API_MESSAGE);
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setState(parseStringToNumber(e.target.value).toFixed(2));
  };

  const handleOpen = () => {
    setShowDrawer(true);
  };

  const handleClose = () => {
    setShowDrawer(false);
  };

  const handleDelete = async () => {
    try {
      await deleteVehicleTaskQuery.mutateAsync();
      handleClose();
      onSaveComplete();
    } catch (e) {
      setApiError(strings.API_MESSAGE);
    }
  };

  const handleMenuClick = (status: TaskStatus) => {
    setStatusLabel(STATUS[status].toUpperCase());
    setStatusValue(status);
    handleClose();
  };

  return (
    <Box className={classes.container}>
      <Box
        className={classes.body}
        style={{
          flex: 1,
          rowGap: theme.spacing(3),
          overflowY: 'auto',
          marginBottom: '40px',
        }}
      >
        <Box className={classes.statusContainer}>
          <Typography
            variant="caption"
            style={{ paddingLeft: '3px', color: '#32323296' }}
          >
            Status
          </Typography>
          <ButtonBase
            disabled={!hasTaskUpdatePermission}
            onClick={(e) => {
              e.stopPropagation();
              handleOpen();
            }}
          >
            <Box
              className={classes[statusLabel.toLowerCase()]}
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-start',
                fontSize: '16px',
                marginTop: '-4px',
              }}
            >
              {statusLabel}
            </Box>
            <ArrowDropDownIcon className={classes.arrow} />
          </ButtonBase>
        </Box>
        <Autocomplete
          id="combo-box-tasks"
          disabled={disabled}
          size="small"
          options={taskTemplates}
          getOptionLabel={(taskTemplate: TaskTemplate) => taskTemplate.label}
          classes={{ popper: classes.autocompletePopper }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={strings.TASK}
              margin="none"
              size="small"
            />
          )}
          value={taskTemplate}
          onChange={(e, taskTemplate) => {
            if (taskTemplate) {
              setTaskTemplate(taskTemplate);
              setAssignee(taskTemplate?.defaultAssignedTo);
              setDescription(taskTemplate?.description ?? '');
              setRate(taskTemplate?.laborRate?.amount?.toString() ?? '0');
              setHours(taskTemplate?.laborHours?.toString() ?? '0');
              setLabor(taskTemplate?.laborPrice?.amount?.toString() ?? '0');
              setParts(taskTemplate?.partsPrice?.amount?.toString() ?? '0');
            }
          }}
          defaultValue={task as unknown as TaskTemplate}
        />
        <Autocomplete
          id="combo-box-assignees"
          disabled={assigneeDisabled}
          size="small"
          options={users}
          getOptionLabel={(user) =>
            getUsersFullName(strings.NOT_ASSIGNED, user)
          }
          classes={{ popper: classes.autocompletePopper }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={strings.ASSIGNEE}
              margin="none"
              size="small"
            />
          )}
          value={assignee}
          onChange={(event, assignee) => {
            if (assignee) {
              setAssignee(assignee);
            }
          }}
          defaultValue={task.assignedTo}
        />
        <TextField
          className={classes.description}
          disabled={disabled}
          label={`${strings.DESCRIPTION} (Optional)`}
          size="small"
          multiline
          variant="outlined"
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {hasTaskAttachmentViewPermission && (
          <TaskPhotos
            task={task}
            vehicle={vehicle}
            disabled={photosDisabled}
            mediaListRender={({ attachments, isLoading, onAdd, onClick }) => (
              <MobileMediaList
                disabled={photosDisabled}
                attachments={attachments}
                onAdd={onAdd}
                onClick={onClick}
                task={task}
                vehicleId={vehicle?.vehicleCard.id}
                isLoading={isLoading}
              />
            )}
          ></TaskPhotos>
        )}
        <Box>
          <Box className={classes.textContainer}>
            {hasTaskLaborCostViewPermission && (
              <TextField
                disabled={disabled || !hasTaskLaborCostUpdatePermission}
                style={{ flex: 1 }}
                label={strings.RATE}
                size="small"
                variant="outlined"
                inputMode="numeric"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <AttachMoneyIcon
                        fontSize="small"
                        style={{
                          color: theme.palette.grey[500],
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={rate}
                onChange={(e) => {
                  const rate = e.target.value;
                  setRate(rate);
                  setLabor(
                    (
                      parseStringToNumber(hours) * parseStringToNumber(rate)
                    ).toFixed(2)
                  );
                }}
                onBlur={(e) => handleBlur(e, setRate)}
              />
            )}
            {hasTaskLaborCostViewPermission && (
              <TextField
                disabled={disabled || !hasTaskLaborCostUpdatePermission}
                style={{ flex: 1 }}
                label={strings.HOURS}
                size="small"
                variant="outlined"
                inputMode="numeric"
                value={hours}
                onChange={(e) => {
                  const hours = e.target.value;
                  setHours(hours);
                  setLabor(
                    (
                      parseStringToNumber(hours) * parseStringToNumber(rate)
                    ).toFixed(2)
                  );
                }}
                onBlur={(e) => handleBlur(e, setHours)}
              />
            )}
          </Box>

          <Box
            className={classes.textContainer}
            style={{ marginTop: theme.spacing(4) }}
          >
            {hasTaskLaborCostViewPermission && (
              <TextField
                disabled={disabled || !hasTaskLaborCostUpdatePermission}
                style={{ flex: 1 }}
                label={strings.LABOR}
                size="small"
                variant="outlined"
                inputMode="numeric"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <AttachMoneyIcon
                        fontSize="small"
                        style={{
                          color: theme.palette.grey[500],
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={labor}
                onChange={(e) => {
                  setLabor(e.target.value);
                  setRate('');
                  setHours('');
                }}
                onBlur={(e) => {
                  handleBlur(e, setLabor);
                }}
              />
            )}
            {hasTaskPartsCostViewPermission && (
              <TextField
                disabled={disabled || !hasTaskPartsCostUpdatePermission}
                style={{ flex: 1 }}
                label={strings.PARTS}
                size="small"
                variant="outlined"
                inputMode="numeric"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <AttachMoneyIcon
                        fontSize="small"
                        style={{
                          color: theme.palette.grey[500],
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={parts}
                onChange={(e) => {
                  setParts(e.target.value);
                }}
                onBlur={(e) => handleBlur(e, setParts)}
              />
            )}
          </Box>

          {hasTaskLaborCostViewPermission && hasTaskPartsCostViewPermission && (
            <Box
              style={{
                flex: 1,
                marginTop: theme.spacing(1),
                paddingTop: theme.spacing(1),
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'row',
                //backgroundColor: theme.palette.grey[300],
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ fontWeight: 600 }}
              >
                {strings.TOTAL.toUpperCase()}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ fontWeight: 600 }}
              >
                {currencyFormatter(total)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Button
          variant="contained"
          className={classes.saveButton}
          color={'secondary'}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
      {task && (
        <MobileFlatTaskMenu
          task={task}
          show={showDrawer}
          onClose={handleClose}
          onDelete={handleDelete}
          onClick={handleMenuClick}
        />
      )}
      <Alert
        open={!!apiError}
        duration={3000}
        handleClose={() => setApiError('')}
        contentProps={{
          variant: 'error',
          message: apiError,
          onClose: () => setApiError(''),
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    body: {
      position: 'absolute',
      top: 42,
      left: 0,
      right: 0,
      bottom: 28,
      padding: theme.spacing(2),
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    col: { display: 'flex', flexDirection: 'column' },
    row: { display: 'flex', flexDirection: 'row', columnGap: 8 },
    selectRoot: {
      zIndex: 9999,
    },
    autocompletePopper: {
      zIndex: 9999,
    },
    addPhoto: {
      marginTop: theme.spacing(1),
      minHeight: '114px',
    },
    addPhotoIconLabel: {
      flexDirection: 'column',
      display: 'flex',
    },
    addPhotoStartIcon: {
      marginLeft: 0,
      marginRight: 0,
    },
    downArrowIcon: {
      flex: 1,
      justifyContent: 'flex-end',
    },
    description: {
      marginTop: theme.spacing(1),
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: 8,
      marginTop: theme.spacing(1),
    },
    textFieldIcon: {
      margin: theme.spacing(1),
    },
    inputAdornment: {
      marginRight: 0,
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(2),
      paddingBottom: 0,
      display: 'flex',
      justifyContent: 'stretch',
      flex: 1,
    },
    saveButton: {
      flex: 1,
      fontWeight: 700,
      color: theme.palette.primary.light,
      margin: `${theme.spacing(2)}px 0`,
    },
    imageList: {
      marginTop: theme.spacing(1),
    },
    statusContainer: {
      display: 'flex',
      //alignItems: 'stretch',
      //flex: 1,
      flexDirection: 'column',
      borderBottom: '1px solid #0000006b',
      paddingBottom: theme.spacing(0.2),
    },
    arrow: {
      color: '#0000008a',
    },
    estimated: {
      color: theme.palette.info.main,
    },
    approved: {
      color: theme.palette.success.main,
    },
    completed: {
      color: theme.palette.success.main,
    },
    requested: {
      color: theme.palette.warning.main,
    },
    denied: {
      color: theme.palette.danger.main,
    },
    invoiced: {
      color: '#44c7ff',
    },
  })
);

export default MobileFlatTaskEdit;
