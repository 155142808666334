import React from 'react';

import { strings } from 'common';

import MobileFlatTaskDialog from '../MobileFlatTaskDialog/MobileFlatTaskDialog';

type Props = {
  title: string;
  children: React.ReactElement;
  open: boolean;
  onClose: () => void;
};

const MobileAddFlatTask = ({ open, children, onClose }: Props) => {
  return (
    <MobileFlatTaskDialog
      open={open}
      onClose={onClose}
      title={strings.ADD_TASK}
    >
      <>{children}</>
    </MobileFlatTaskDialog>
  );
};

export default MobileAddFlatTask;
