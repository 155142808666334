import { HelpOutline } from '@material-ui/icons';
import { FC } from 'react';

import permissions from 'common/permissions';
import strings from 'common/strings';
import PermissionsGate from 'components/shared/PermissionsGate';
import { SummaryItem } from 'models';
import { currencyFormatter } from 'utils/formatter';
import { getFormattedDurationFromSeconds } from 'utils/time';

import './SummaryCard.scss';

interface SummaryCardProps {
  summaryItem: SummaryItem;
  setStyle?: string;
  callbackShowDialog?: any;
  statSelectable?: boolean;
  onStatClick?: any;
  testId?: string;
  icon?: any;
}

const SummaryCard: FC<SummaryCardProps> = ({
  summaryItem,
  setStyle,
  callbackShowDialog,
  statSelectable,
  onStatClick,
  testId,
  icon,
}) => {
  function getFormattedStatValue() {
    if (summaryItem.statValue) {
      if (
        summaryItem.itemType === strings.TIME_GOAL ||
        summaryItem.unit === strings.SECONDS ||
        summaryItem.unit === strings.NUMBER_SECONDS
      ) {
        return getFormattedDurationFromSeconds(summaryItem.statValue, 2);
      }
      if (summaryItem.unit === strings.DOLLARS) {
        return currencyFormatter(summaryItem.statValue);
      }
    }
    return summaryItem.statValue;
  }

  function getFormattedGoalValue() {
    if (summaryItem.goalValue) {
      if (
        summaryItem.unit === strings.SECONDS ||
        summaryItem.unit === strings.NUMBER_SECONDS ||
        summaryItem.itemType === strings.TIME_GOAL
      ) {
        return getFormattedDurationFromSeconds(summaryItem.goalValue);
      }
    }
    return summaryItem.goalValue;
  }

  function onHelperClick() {
    callbackShowDialog();
  }

  const statContainerProps = statSelectable
    ? {
        className: 'SummaryCard-base-stat-container SummaryCard-clickable',
        onClick: onStatClick,
        role: 'button',
        tabIndex: -1,
      }
    : { className: 'SummaryCard-base-stat-container' };

  const status = () => {
    if (summaryItem.statValue && summaryItem.goalValue) {
      if (summaryItem.itemType === strings.TIME_GOAL) {
        if (summaryItem.statValue > summaryItem.goalValue) {
          return 'bad';
        }
        return 'good';
      }
      if (summaryItem.itemType === strings.COST_GOAL) {
        if (summaryItem.statValue < summaryItem.goalValue) {
          return 'bad';
        }
        return 'good';
      }
    }
    return 'neutral';
  };

  return (
    <div className="SummaryCard" data-vas-testing={testId}>
      {callbackShowDialog && (
        <HelpOutline className="SummaryCard-icon" onClick={onHelperClick} />
      )}
      <div {...statContainerProps}>
        <div className="SummaryCard-base-stat-value-container">
          <div className="SummaryCard-base-stat-value-icon">
            {icon ? icon : undefined}
          </div>
          <span
            className={
              'SummaryCard-base-stat-value-text SummaryCard-' +
              (setStyle || status())
            }
          >
            {summaryItem?.statAltValue === undefined
              ? summaryItem.statValue && getFormattedStatValue()
              : summaryItem?.statAltValue}
            {summaryItem.content && summaryItem.content}
          </span>
        </div>
        <div
          className={`SummaryCard-base-stat-label-container ${
            !!summaryItem?.goalValue ? 'hasGoal' : ''
          }`}
        >
          <span className="SummaryCard-base-stat-label-text">
            {summaryItem.statLabel}
          </span>
        </div>
      </div>
      {!!summaryItem?.goalValue && (
        <PermissionsGate permissions={[permissions.DASHBOARD_GOALS_VIEW]}>
          <div>
            <hr className="SummaryCard-divider" />
          </div>
          <div className="SummaryCard-goal-container">
            <div className="SummaryCard-goal-label-container">
              <span className="SummaryCard-goal-label-text">
                {summaryItem.goalLabel ||
                  (summaryItem.itemType === strings.TIME_GOAL
                    ? strings.GOAL
                    : strings.WORK_IN_PROGRESS)}
              </span>
            </div>
            <div className="SummaryCard-goal-value-container">
              <span className="SummaryCard-goal-value-text">
                {getFormattedGoalValue()}
              </span>
            </div>
          </div>
        </PermissionsGate>
      )}
    </div>
  );
};

export default SummaryCard;
