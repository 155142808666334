import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useCallback, useState } from 'react';

import usePayInvoiceMutation from 'api/invoicing/usePayInvoiceMutation';
import useSendInvoiceMutation from 'api/invoicing/useSendInvoiceMutation';
import { strings } from 'common';
import Button from 'components/shared/Button';
import { Invoice } from 'models/invoicing';

const InvoiceDialog = ({
  open,
  onClose,
  title,
  description,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
}) => (
  <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={onClose}>
    <DialogContent
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '350px',
      }}
    >
      <CheckCircleOutlineIcon style={{ color: green[500], fontSize: 150 }} />
      <Typography variant="subtitle1">{title}</Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="secondary" type="button" onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

type Props = {
  invoice?: Invoice;
  invoiceNumber?: string;
  purchaseOrderNumber?: string;
  showPayButton?: boolean;
  showSendButton?: boolean;
  showUpdateButton?: boolean;
};

const Footer = ({
  invoice,
  invoiceNumber,
  purchaseOrderNumber,
  showPayButton,
  showSendButton,
  showUpdateButton,
}: Props) => {
  const sendInvoiceMutation = useSendInvoiceMutation();

  const invoiceId = invoice?.id || '';
  const payInvoiceMutation = usePayInvoiceMutation(invoiceId);

  const [openedDialog, setOpenedDialog] = useState('');

  const handleCloseDialog = useCallback(() => setOpenedDialog(''), []);

  const handleSendInvoice = useCallback(async () => {
    if (invoiceId && invoiceNumber) {
      try {
        await sendInvoiceMutation.mutateAsync({
          invoiceId,
          invoiceNumber,
          purchaseOrderNumber,
        });
        setOpenedDialog('sent');
      } catch (error) {
        console.error(error);
      }
    }
  }, [invoiceId, invoiceNumber, purchaseOrderNumber, sendInvoiceMutation]);

  const handlePayInvoice = useCallback(async () => {
    if (invoiceId) {
      try {
        await payInvoiceMutation.mutateAsync();
        if (purchaseOrderNumber) {
          await handleSendInvoice();
        }
        setOpenedDialog('paid');
      } catch (error) {
        console.error(error);
      }
    }
  }, [handleSendInvoice, invoiceId, payInvoiceMutation, purchaseOrderNumber]);

  const paidDialogOpen = openedDialog === 'paid';
  if (showPayButton || paidDialogOpen) {
    return (
      <footer className="vendor-view-invoice-footer">
        <Button
          variant="primary"
          type="button"
          onClick={handlePayInvoice}
          disabled={
            payInvoiceMutation.isLoading ||
            payInvoiceMutation.isSuccess ||
            sendInvoiceMutation.isLoading ||
            sendInvoiceMutation.isSuccess
          }
        >
          {strings.PAY_INVOICE}
        </Button>
        <InvoiceDialog
          open={paidDialogOpen}
          onClose={handleCloseDialog}
          title="Invoice paid!!!"
          description="Invoice paid!!!"
        />
      </footer>
    );
  }

  const sentDialogOpen = openedDialog === 'sent';
  if (showSendButton || sentDialogOpen) {
    return (
      <footer className="vendor-view-invoice-footer">
        <Button
          variant="primary"
          type="button"
          onClick={handleSendInvoice}
          disabled={
            !invoiceNumber ||
            sendInvoiceMutation.isLoading ||
            sendInvoiceMutation.isSuccess
          }
        >
          {strings.SEND_INVOICE}
        </Button>
        <InvoiceDialog
          open={sentDialogOpen}
          onClose={handleCloseDialog}
          title="Invoice sent!"
          description="Invoice sent!"
        />
      </footer>
    );
  }

  const updateDialogOpen = openedDialog === 'update';
  if (showUpdateButton || updateDialogOpen) {
    return (
      <footer className="vendor-view-invoice-footer">
        <Button
          variant="primary"
          type="button"
          onClick={handleSendInvoice}
          disabled={
            !purchaseOrderNumber ||
            sendInvoiceMutation.isLoading ||
            sendInvoiceMutation.isSuccess
          }
        >
          {strings.UPDATE_INVOICE}
        </Button>
        <InvoiceDialog
          open={updateDialogOpen}
          onClose={handleCloseDialog}
          title="Invoice updated!"
          description="Invoice updated!"
        />
      </footer>
    );
  }

  return null;
};

export default Footer;
