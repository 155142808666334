import { generateTestId, testIds } from 'common/testIds';
import { Task, TaskStatus, TaskTemplate } from 'models';

export const getTaskRowTestIds = (variant: string, position: number) => {
  const checkboxId = generateTestId(testIds.TASK_ROW_CHECKBOX, {
    variant,
    position: position.toString(),
  });

  const containerId = generateTestId(testIds.TASK_ROW_CONTAINER_VIEW, {
    variant,
    position: position.toString(),
  });

  const customTaskId = generateTestId(testIds.TASK_ROW_CUSTOM_NAME_TEXT, {
    variant,
    position: position.toString(),
  });

  const taskTypeClass = generateTestId(
    testIds.SELECT_TASK_TYPE_CLASSNAME_PREFIX_CSS,
    {
      variant,
      position: position.toString(),
    }
  );

  const taskTypeId = generateTestId(testIds.TASK_ROW_SELECT_TASK_TYPE_TEXT, {
    variant,
    position: position.toString(),
  });

  const descriptionId = generateTestId(testIds.TASK_ROW_DESCRIPTION_TEXT, {
    variant,
    position: position.toString(),
  });

  const assigneeClass = generateTestId(
    testIds.SELECT_TASK_ASSIGNEE_CLASSNAME_PREFIX_CSS,
    {
      variant,
      position: position.toString(),
    }
  );

  const assigneeId = generateTestId(testIds.TASKS_ROW_SELECT_ASSIGNEE_TEXT, {
    variant,
    position: position.toString(),
  });

  const partsCostId = generateTestId(testIds.TASK_ROW_PARTS_COST_TEXT, {
    variant,
    position: position.toString(),
  });

  const laborCostId = generateTestId(testIds.TASK_ROW_LABOR_COST_TEXT, {
    variant,
    position: position.toString(),
  });

  const totalCostId = generateTestId(testIds.TASK_ROW_TOTAL_COST_TEXT, {
    variant,
    position: position.toString(),
  });

  const deleteId = generateTestId(testIds.TASK_ROW_DELETE_BUTTON, {
    variant,
    position: position.toString(),
  });

  return {
    checkboxId,
    containerId,
    customTaskId,
    taskTypeClass,
    taskTypeId,
    descriptionId,
    assigneeClass,
    assigneeId,
    partsCostId,
    laborCostId,
    totalCostId,
    deleteId,
  };
};

export const taskGroups = {
  estimated: ['DRAFT'] as TaskStatus[],
  request: ['PENDING_APPROVAL'] as TaskStatus[],
  todo: ['APPROVED_PENDING_COMPLETE', 'COMPLETE'] as TaskStatus[],
  invoiced: ['INVOICED'] as TaskStatus[],
  paid: ['PAID'] as TaskStatus[],
  denied: ['DENIED'] as TaskStatus[],
};

export const buildSortedTasks = (tasks: Task[]) => {
  const estimates = tasks
    ?.filter((task) => taskGroups.estimated.indexOf(task.status) !== -1)
    .sort(taskSorter);
  const requests = tasks
    ?.filter((task) => taskGroups.request.indexOf(task.status) !== -1)
    .sort(taskSorter);
  const todo = buildToDoTasks(tasks);
  const invoiced = tasks?.filter(
    (task) => taskGroups.invoiced.indexOf(task.status) !== -1
  );
  const paid = tasks?.filter(
    (task) => taskGroups.paid.indexOf(task.status) !== -1
  );
  const denied = tasks
    ?.filter((task) => taskGroups.denied.indexOf(task.status) !== -1)
    .sort(taskSorter);
  return estimates.concat(requests, todo, invoiced, paid, denied);
};

const buildToDoTasks = (tasks: Task[]) => {
  const todoTasks = tasks.filter(
    (task) => taskGroups.todo.indexOf(task.status) !== -1
  );
  const completeTasks = todoTasks
    .filter((task) => task.status === 'COMPLETE')
    .sort(taskSorter);
  const incompleteTasks = todoTasks
    .filter((task) => task.status !== 'COMPLETE')
    .sort(taskSorter);
  return incompleteTasks.concat(completeTasks);
};

const taskSorter = (taskA: Task, taskB: Task) => {
  const taskAId = taskA?.id ?? '';
  const taskBId = taskB?.id ?? '';
  return taskAId < taskBId ? 1 : -1;
};

export const newTask = () => {
  const task: Task = {
    status: 'DRAFT',
    laborHours: 0,
    laborPrice: {
      amount: 0,
      currency: 'USD',
    },
    partsPrice: {
      amount: 0,
      currency: 'USD',
    },
    laborRate: {
      amount: null,
      currency: 'USD',
    },
    attachments: [],
    label: '',
    invoiceId: '',
  };
  return task;
};

export const taskFromTaskTemplate = (
  task: Task,
  taskTemplate?: TaskTemplate
) => {
  if (!taskTemplate) {
    return task;
  }

  return {
    ...task,
    label: taskTemplate.label ?? '',
    taskTemplateId: !taskTemplate?.id.startsWith('custom')
      ? taskTemplate?.id
      : undefined,
    assignedTo: taskTemplate.defaultAssignedTo,
    assignedToId: taskTemplate.defaultAssignedTo?.id,
    notes: taskTemplate.description,
    laborRate: taskTemplate.laborRate,
    laborHours: taskTemplate.laborHours,
    laborPrice: taskTemplate.laborPrice,
    partsPrice: taskTemplate.partsPrice,
  };
};
