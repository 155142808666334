import { Button } from '@material-ui/core';
import { ChevronLeft, ChevronRight, GetApp } from '@material-ui/icons';
import React from 'react';
import Carousel, {
  CommonProps,
  Modal,
  ModalGateway,
  ViewType,
} from 'react-images';

import { hasImageContentType, isImage } from 'common/images';
import permissions from 'common/permissions';
import { hasVideoContentType } from 'common/video';
import { VehicleImage } from 'models';

import CustomHeader from './CustomHeader';

import './ModalCarousel.scss';

const CustomNextButton: React.FC<CommonProps> = ({ innerProps }) => (
  <button
    {...innerProps}
    type="button"
    className="ModalCarousel-nav-button ModalCarousel-nav-button-next"
  >
    <ChevronRight />
  </button>
);

const CustomPreviousButton: React.FC<CommonProps> = ({ innerProps }) => (
  <button
    type="button"
    className="ModalCarousel-nav-button ModalCarousel-nav-button-previous"
    {...innerProps}
  >
    <ChevronLeft />
  </button>
);

const CustomView: React.FC<CommonProps> = (props) => {
  const { innerProps } = props;
  let currentView = props.currentView as ViewType & {
    name?: string;
    type?: string;
    contentType?: string;
  };

  const source: string = (currentView?.source as string) || '';
  const contentType = currentView?.contentType;

  if (hasImageContentType(contentType) || isImage(source)) {
    return (
      <div className="ModalCarousel-view">
        <img
          {...innerProps}
          src={source}
          alt="media"
          className="ModalCarousel-view-image"
        />
      </div>
    );
  }

  if (hasVideoContentType(contentType)) {
    return (
      <div className="ModalCarousel-view">
        <video
          {...innerProps}
          src={source as string}
          controls
          className="ModalCarousel-view-image"
        />
      </div>
    );
  }

  return (
    <div className="ModalCarousel-view">
      <object
        {...innerProps}
        data={source}
        type={contentType}
        className="ModalCarousel-view-attachment"
      >
        <div
          className="ModalCarousel-view-attachment-icon"
          onClick={() => window.open(source)}
        >
          <GetApp />
          <Button
            variant="contained"
            color="secondary"
            href="#contained-buttons"
          >
            Download
          </Button>
        </div>
      </object>
    </div>
  );
};

interface ModalCarouselProps {
  images: VehicleImage[];
  modalIsOpen: boolean;
  currentIndex: number;
  onClose: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void;
  onDeleteImage?: (id: string) => void;
  onAssignImage?: (id: string, shot: string) => void;
  deletePermission?: string;
  assignPermission?: string;
}

const ModalCarousel: React.FC<ModalCarouselProps> = (props) => {
  const {
    images,
    modalIsOpen,
    currentIndex,
    onClose,
    onDeleteImage,
    onAssignImage,
    deletePermission = permissions.INVENTORY_VDP_PHOTOS_DELETE,
    assignPermission = permissions.INVENTORY_VDP_PHOTOS_CREATE,
  } = props;
  const views = images.map((image: VehicleImage) => ({
    source: image.uri,
    name: image.name,
    createdDate: image.createdAt,
    id: image.id,
    type: image.imageType,
    contentType: image.contentType,
  }));

  return (
    <ModalGateway>
      {modalIsOpen && (
        <Modal
          styles={{
            blanket: (base: any) => ({ ...base, zIndex: 10000 }),
            positioner: (base: any) => ({ ...base, zIndex: 10000 }),
            dialog: (base: any) => ({ ...base, zIndex: 10000 }),
          }}
          onClose={onClose}
        >
          <Carousel
            views={views}
            currentIndex={currentIndex}
            components={{
              Header: (props) => (
                <CustomHeader
                  {...props}
                  innerProps={{
                    onDeleteImage,
                    onAssignImage,
                    deletePermission,
                    assignPermission,
                    ...props.innerProps,
                  }}
                />
              ),
              NavigationNext: CustomNextButton,
              NavigationPrev: CustomPreviousButton,
              View: CustomView,
            }}
          />
        </Modal>
      )}
    </ModalGateway>
  );
};

export default ModalCarousel;
